.custom-offcanvas {
  width: 75vw !important;

  .border-solid-right {
    border-right: 1px solid #efefef;
  }

  .profile-nav {
    color: #00bd9d;
  }
  .userIcon {
    color: #00bd9d;
  }
  .nav-icon {
    cursor: pointer;
  }
  .cursor-disable {
    cursor: not-allowed;
  }
}
