$primary-color: grey;
.bottom-container {
  .bottom-container-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .counter {
      color: $primary-color;
    }
  }
}
