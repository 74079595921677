//
// _autocomplete.scss
//

.autoComplete_wrapper {
  display: block;

  > input {
    display: block;
    width: 100%;
    height: auto;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    background-image: none;

    &::placeholder {
      padding: 0 !important;
      color: $input-placeholder-color !important;
      font-size: $input-font-size !important;
    }

    &:focus {
      border: $input-border-width solid $input-focus-border-color;
      color: $input-focus-color;
    }

    &:hover {
      color: $input-color;
    }
  }

  > ul {
    border-radius: $border-radius;
    border-color: $dropdown-border-color;
    background-color: $dropdown-bg;
    box-shadow: $dropdown-box-shadow;
    padding: 0;
    overflow: auto;
    max-height: 160px;
    margin: 0;
    animation-name: DropDownSlide;
    animation-duration: 0.3s;
    animation-fill-mode: both;

    > li {
      font-size: $dropdown-font-size;
      margin: 0;
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      border-radius: 0;
      background-color: $dropdown-bg;
      color: $dropdown-color;

      mark {
        color: $danger;
        font-weight: $font-weight-semibold;
        padding: 1px;
      }

      &[aria-selected="true"],
      &:hover {
        color: $dropdown-link-hover-color;
        @include gradient-bg($dropdown-link-hover-bg);
      }
    }

    .no_result {
      padding: $dropdown-item-padding-y * 2 $dropdown-item-padding-x;
      font-style: italic;
      font-weight: $font-weight-medium;
    }
  }
}
