.campaignTable {
  .wrapContent {
    white-space: pre-wrap;
    text-align: justify;
    text-justify: inter-word;
  }
  .countsCol {
    width: "5%" !important;
  }
}
