//
//  _sweetalert2.scss
//

.swal2-container {
  .swal2-title {
    padding: 24px 24px 0;
    font-size: 20px;
    font-weight: $font-weight-medium;
  }
}

.swal2-popup {
  padding-bottom: 24px;
  border-radius: $modal-content-border-radius;
  background-color: var(--#{$prefix}secondary-bg);
  color: var(--#{$prefix}body-color);

  .swal2-title {
    color: var(--#{$prefix}heading-color);
  }

  .swal2-html-container {
    color: var(--#{$prefix}body-color);
  }
}

.swal2-footer {
  border-top: 1px solid var(--#{$prefix}border-color);
  color: var(--#{$prefix}body-color);
}

.swal2-html-container {
  font-size: 16px;
}

.swal2-icon {
  &.swal2-question {
    border-color: $info;
    color: $info;
  }
  &.swal2-success {
    [class^="swal2-success-line"] {
      background-color: $success;
    }

    .swal2-success-ring {
      border-color: rgba($success, 0.3);
    }
  }
  &.swal2-warning {
    border-color: $warning;
    color: $warning;
  }
}

.swal2-styled {
  &:focus {
    box-shadow: none;
  }
}

.swal2-loader {
  border-color: $primary transparent $primary transparent;
}

.swal2-timer-progress-bar {
  background-color: rgba($success, 0.4);
}

.swal2-progress-steps {
  .swal2-progress-step {
    background: $primary;
    &.swal2-active-progress-step {
      background: $primary;
      & ~ .swal2-progress-step,
      & ~ .swal2-progress-step-line {
        background: rgba($primary, 0.3);
      }
    }
  }
  .swal2-progress-step-line {
    background: $primary;
  }
}

.swal2-actions.swal2-loading {
  .swal2-styled.swal2-confirm {
    border-left-color: $primary !important;
    border-right-color: $primary !important;
  }
}

.swal2-file,
.swal2-input,
.swal2-textarea {
  border: 1px solid $input-border-color;
  &:focus {
    box-shadow: none;
    border-color: $input-focus-border-color;
  }
}

.swal2-input {
  height: auto;
  display: block;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
}

.swal2-close {
  font-family: $font-family-base;
  font-weight: $font-weight-light;
  font-size: 28px;
  &:focus {
    box-shadow: none;
  }

  &:hover {
    color: $primary;
  }
}

.swal2-validation-message {
  background-color: transparent;
}
